#bg-cont {
  z-index: -10;
  width: calc(100vw + 100px);
  height: calc(100vh + 200px);
  background-color: #000;
  transition: all .3s cubic-bezier(0, .55, .45, 1);
  position: fixed;
  top: 0;
  right: -100px;
}

#bg-cont picture {
  width: 100%;
  height: 100%;
  display: flex;
}

#bg-cont picture img {
  object-fit: cover;
  height: auto;
  width: 100%;
}

@media (max-width: 1140px) {
  #bg-cont {
    transform: none !important;
  }
}

/*# sourceMappingURL=index.d52341d4.css.map */
