@import "constants";

#bg-cont {
  position: fixed;
  top: 0;
  right: -100px;
  z-index: -10;
  width: calc(100vw + 100px);
  height: calc(100vh + 200px);
  transition: 0.3s all cubic-bezier(0, 0.55, 0.45, 1);
  background-color: black;

  picture {
    display: flex;
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      height: auto;
      width: 100%;
    }
  }
}

@media (max-width: $mobile-screen-width) {
  #bg-cont {
    transform: none !important;
  }
}
